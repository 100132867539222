import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import TextField from '@mui/material/TextField';
import moment from 'moment-timezone';
import Button from '@mui/material/Button';
import { ChartOptions, Tick } from 'chart.js';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Transaction {
    datetime: string;
    value: number;
    notifications: number;
}

interface ClientData {
    client: string;
    transactions: Transaction[];
}

interface DateSumCardProps {
    title: string;
    sum: string;
    period: string
  }
  

  const DateSumCardComponent: React.FC<DateSumCardProps> = ({ title, sum, period }) => (
    <Card variant="outlined" sx={{ boxShadow: 3, padding: 2, borderRadius: 2, backgroundColor: '#b9d2d8' }}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom variant="h6">
          {title}
        </Typography>
        <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
          {sum}
        </Typography>
        <Typography color="gray" gutterBottom variant="subtitle2">
          {period}  {/* Exibindo o período aqui */}
        </Typography>
      </CardContent>
    </Card>
  );
  


const CardComponent: React.FC<{ clientData: ClientData }> = ({ clientData }) => {
    const totalSum = clientData.transactions.reduce((acc, transaction) => acc + transaction.value, 0);
    return (
        <Card variant="outlined" sx={{ margin: 2 }}>
            <CardContent>
                <Typography color="textSecondary" gutterBottom>
                    {clientData.client}
                </Typography>
                <Typography variant="h5" component="h2">
                    R$ {totalSum.toFixed(2)}
                </Typography>
            </CardContent>
        </Card>
    );
};

const ChartComponent: React.FC<{ data: ClientData[] }> = ({ data }) => {
    // Mapeamento de data formatada para data original
    const formattedDateMap = new Map();

    const labels = Array.from(new Set(data.flatMap(d => d.transactions.map(t => {
        const originalDate = t.datetime.split('T')[0];
        const formattedDate = new Date(originalDate).toLocaleDateString('pt-BR');
        formattedDateMap.set(formattedDate, originalDate);
        return formattedDate;
    }))));

    const chartData = {
        labels,
        datasets: [{
            label: 'Valor total',
            data: labels.map(label => {
                const originalDate = formattedDateMap.get(label);
                return data.flatMap(d => d.transactions)
                    .filter(t => t.datetime.split('T')[0] === originalDate)
                    .reduce((acc, t) => acc + t.value, 0);
            }),
            backgroundColor: '#f05365',
            borderColor: '#f05365'
        }]
    };

    const options: ChartOptions<'bar'> = {
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'R$'
                },
                ticks: {
                    callback: (tickValue: string | number, index: number, ticks: Tick[]) => {
                        const value = typeof tickValue === 'number' ? tickValue : parseFloat(tickValue);
                        return 'R$ ' + value.toFixed(2);
                    }
                },
                grid: {
                    display: true, // Display the grid lines
                    color: 'rgba(0, 0, 0, 0.1)', // Line color
                },
            },
            x: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = '';
                        let totalNotifications = 0;

                        if (context.parsed.y !== null) {
                            // Calcula o total de notificações para o dia específico
                            totalNotifications = data.flatMap(d => d.transactions)
                                .filter(t => t.datetime.split('T')[0] === context.label)
                                .reduce((acc, t) => acc + (t.notifications ?? 1), 0); // Se for NaN, use 1

                            const envioText = totalNotifications === 1 ? 'envio' : 'envios';
                            label = `R$ ${context.parsed.y.toFixed(2)} | ${totalNotifications} ${envioText}`;
                        }
                        
                        return label;
                    }
                }
            }
        }
    };
    

    return (
        <div style={{ borderRadius: 5, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', width: '100%', height: '80vh', backgroundColor: '#b9d2d8', padding: '20px' }}>
            <Bar data={chartData} options={options} />
        </div>
    );
};

const FilterComponent: React.FC<{ onFilterChange: (type: string, value: Date | null | string) => void }> = ({ onFilterChange }) => (
    <div className="filter">
        <LocalizationProvider>
            <DateTimePicker
                label="Start Date"
                onChange={(date: Date | null) => onFilterChange('startDate', date)}
                renderInput={(params: any) => <TextField {...params} />}
            />
            <DateTimePicker
                label="End Date"
                onChange={(date: Date | null) => onFilterChange('endDate', date)}
                renderInput={(params: any) => <TextField {...params} />}
            />
        </LocalizationProvider>
    </div>
);

const Dashboard: React.FC = () => {
    const [data, setData] = useState<ClientData[]>([]);
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const blipTenantId = queryParams['blip-tenant-id'];
    const blipBotKey = queryParams['blip-bot-key'];
    const [filter, setFilter] = useState({
        client: blipTenantId,
        startDate: null as Date | null,
        endDate: null as Date | null
    });


    const cardData = [
        { title: "Valor total", sum: 'R$ 10.000,00', period: 'Último mês' },
        { title: "Total de envios", sum: '4', period: 'Último mês' },
        { title: "Valor médio dos carrinhos", sum: 'R$ 1.000,00', period: 'Último mês' },
        { title: "Envios bloqueados por pedido existente", sum: '10', period: 'Último mês'}
      ];
   

    useEffect(() => {
        const fetchData = async () => {
            const url = window.location.href; // This gives you the current URL
            
            try {
                var response;

                if (url.includes('localhost') || url.includes('api-dev')) {
                    response = await axios.get('http://localhost:8080/newrelic/data/vtex', {
                        headers: {
                            'api-key': 'bd676752-7091-4a4d-92a8-b5568938b7e8'
                        }
                    });
                } else {
                    response = await axios.get('https://api.catalyst.dwnt.store/newrelic/data/vtex', {
                        headers: {
                            'api-key': '1060684d-9acf-4d27-943f-c20d7a6a52f0'
                        }
                    });

                }
                
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleFilterChange = (type: string, value: Date | null | string) => {
        if (type === 'client') {
            setFilter({ ...filter, [type]: value as string });
        } else {
            const dateInGMT3 = value ? moment(value).tz('America/Sao_Paulo') : null;
            setFilter({ ...filter, [type]: dateInGMT3 as Date | null });
        }
    };

    const filteredData = data
        .filter(clientData => filter.client === '' || (filter.client && clientData.client === filter.client))
        .reduce((acc, clientData) => {
            const filteredTransactions = clientData.transactions.filter(transaction => {
                const transactionDate = new Date(transaction.datetime).getTime();
                const startDate = filter.startDate ? filter.startDate.valueOf() : 0;
                const endDate = filter.endDate ? filter.endDate.valueOf() : new Date().getTime();

                return transactionDate >= startDate && transactionDate <= endDate;
            });

            if (filteredTransactions.length > 0) {
                acc.push({
                    client: clientData.client,
                    transactions: filteredTransactions
                });
            }

            return acc;
        }, [] as ClientData[]);


        const dateSums = filteredData.reduce((acc, clientData) => {
        clientData.transactions.forEach(transaction => {
            const date = transaction.datetime.split('T')[0];
            if (!acc[date]) {
                acc[date] = 0;
            }
            acc[date] += transaction.value;
        });
        return acc;
        }, {} as Record<string, number>); // Use Record type to avoid any type error

        const dateSumArray = Object.keys(dateSums).map(date => ({ date, sum: dateSums[date] }));

        const exportChartAsImage = () => {
            /*
            const chart = ChartJS.getChart('chartId'); // Replace 'chartId' with your chart's ID
            const image = chart.toBase64Image();
            saveAs(image, 'chart.png');*/

            alert('to do')
        };
        
        // Function to handle export as CSV
        const exportDataAsCSV = () => {
            /*
            let csvContent = "data:text/csv;charset=utf-8,Client,Date,Value\n";
            data.forEach(client => {
                client.transactions.forEach(trans => {
                    csvContent += `${client.client},${trans.datetime},${trans.value}\n`;
                });
            });
            const encodedUri = encodeURI(csvContent);
            saveAs(encodedUri, 'data.csv');*/
            alert('to do')
        };

        return (
            <div style={{ 
                backgroundColor: '#32484e', 
                minHeight: '100vh', // Garante que o container principal tenha pelo menos a altura da tela
                minWidth: '100vw', // Garante que o container principal tenha pelo menos a largura da tela
                padding: '20px' 
            }}>
              <FilterComponent onFilterChange={handleFilterChange} />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '60px', marginLeft: '4%', marginRight: '8%' }}>
                <div style={{ width: '70%' }}>
                  {filter.client !== '' && <ChartComponent data={filteredData} />}
                </div>
                <div style={{ flexBasis: '35', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', gap: '12px' }}>
                  {cardData.map((card, index) => (
                    <DateSumCardComponent key={index} title={card.title} sum={card.sum} period={card.period} />
                  ))}
                </div>
              </div>
            </div>
          );
      };

export default Dashboard;
